import classes from './Shared.module.scss'

interface IEmail {
  where?: string
  showIcon?: boolean
}

export default function Email(props: IEmail) {
  return (
    <a
      className={[classes.Email, classes[props.where ?? 'classic']].join(' ')}
      href="mailto: agcsportscenter@gmail.com"
      title="Click to email Alabama Gulf Coast Sports Center"
    >
      agcsportscenter@gmail.com
    </a>
  )
}